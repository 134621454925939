import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "@fortawesome/free-regular-svg-icons";

import { FormatCurrency } from "../../api/helpers/stringHelpers";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";

import {
    ApplyCoupon,
    CheckoutCartEmpty,
    EmptyCartButton,
    ShoppingCartProductsList,
} from "./CheckoutCartPageComponents";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { t } from "i18next";

interface CheckoutCheckoutCartPageProps {}

const CheckoutCheckoutCartPage: FunctionComponent<
    CheckoutCheckoutCartPageProps
> = () => {
    const shoppingCart = useShoppingCart();
    const navigate = useNavigate();

    useEffect(() => {
        async function recalcular() {
            // TODO: Recalcular el carrito con los precios actuales y validar disponibilidad de los productos
            // TODO: Crear aqui el paymentIntent y pasar el secret al componente de pago
            //       Quitarlo de app.tsx para no generar paymentIntent que no se van a usar
        }
        recalcular();
    }, []);

    if (shoppingCart.getCartNumberOfItems() === 0) return <CheckoutCartEmpty />;

    return (
        <div id="CheckoutCartPage">
            <div id="Checkout-title">
                <div id="Checkout-title--left" className="flex-title">
                    <NavigateBackButton navigateToUrl=".." />
                    <h2>{t("your_order")}</h2>
                </div>
                <EmptyCartButton shoppingCart={shoppingCart} />
            </div>

            <section id="CheckoutCartPage-products">
                <h2>{t("products")}</h2>
                <ShoppingCartProductsList shoppingCart={shoppingCart} />
            </section>

            <div id="CheckoutCartPage-products--bottom">
                <div id="CheckoutCartPage-products--bottom-row1">
                    <div id="CheckoutCartPage-subtotal">
                        <label id="CheckoutCartPage-subtotal--label">
                            {t("subtotal")}
                        </label>
                        <label id="CheckoutCartPage-subtotal--value">
                            {FormatCurrency(
                                shoppingCart.getSubTotalWithDiscount(),
                                true
                            )}
                        </label>
                    </div>
                    <ApplyCoupon shoppingCart={shoppingCart} />
                </div>
                <div id="CheckoutCartPage-products--bottom-row2">
                    <button
                        className="btn-success w-100"
                        onClick={() => navigate("../checkout-info")}
                    >
                        <span className="me-2">{t("checkout")}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutCheckoutCartPage;
